$(document).ready(function () {
	$("#banner").slick({
		infinite: true,
		arrows: true,
		dots: true,
		fade: true,
		autoplay: false,
		speed: 500,
		autoplaySpeed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnFocus: true,
		pauseOnHover: true,
        nextArrow: '<i class="fas fa-chevron-right"></i>',
        prevArrow: '<i class="fas fa-chevron-left"></i>',
		responsive: [
			{
				breakpoint: 767,
				settings: {
					arrows: false,
					slidesToShow: 1,
		            slidesToScroll: 1,
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					adaptiveHeight: true,
				}
			}
		]
	});

	$("#banner").on('afterChange', function(event, slick, currentSlide, nextSlide){
		$('#banner .banner-item').find('.banner-det-overlay').css({height:0});
		$('#banner .banner-item.slick-current').find('.banner-det-overlay').css({height:'100%'});
	}).trigger('afterChange');

    $("#industries-served .carousel-blurb-hld").slick({
		infinite: true,
		arrows: true,
		dots: true,
		fade: false,
		autoplay: true,
		speed: 500,
		autoplaySpeed: 3000,
		slidesToShow: 3,
		slidesToScroll: 3,
		pauseOnFocus: true,
		pauseOnHover: true,
        nextArrow: '<i class="fas fa-chevron-right"></i>',
        prevArrow: '<i class="fas fa-chevron-left"></i>',
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
		            slidesToScroll: 2,
				}
			},
            {
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
		            slidesToScroll: 1,
				}
			}
		]
	});

	$("#products-applications .carousel-blurb-hld").slick({
		infinite: true,
		arrows: true,
		dots: true,
		fade: false,
		autoplay: true,
		speed: 500,
		autoplaySpeed: 2500,
		slidesToShow: 3,
		slidesToScroll: 3,
		pauseOnFocus: true,
		pauseOnHover: true,
        nextArrow: '<i class="fas fa-chevron-right"></i>',
        prevArrow: '<i class="fas fa-chevron-left"></i>',
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
		            slidesToScroll: 2,
				}
			},
            {
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
		            slidesToScroll: 1,
				}
			}
		]
	});
});